import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Jonathon Myers is the co-founder of `}<a parentName="p" {...{
        "href": "https://www.earplay.com/"
      }}>{`Earplay`}</a>{`, a voice interactive storytelling platform. He sat down with Dashbot and shared some of the insights he gathered through working in the voice space for almost a decade.`}</p>
    <h2>{`How did you get started in voice?`}</h2>
    <p>{`I got my MFA in playwriting and felt no way of making a living off that, so I started working for Zynga as a writer. Social games were becoming a big deal on Facebook and mobile. I was pretty good at thinking about the interactive narrative and formed my own company to focus on interactive conversation when I had the chance. I took what I learned in the games industry and brought it to my passion, which was drama.`}</p>
    <h2>{`Can you tell me more about Earplay in your own words?`}</h2>
    <p>{`Earplay is actually changing a bit right now. For a long time, we’ve been what we call a content studio, building stories and games. We got our start very early on in 2013, and we had the opportunity to build some really powerful technology that only we’ve been using. We’ve found out that other people are interested in our interactive audio technology, so Earplay is now a platform for the creation and cross-service distribution of voice-driven interactive experiences.`}</p>
    <p>{`We put out some top notch blockbuster hits like `}<a parentName="p" {...{
        "href": "https://www.earplay.com/stories/jurassicworldrevealed/"
      }}>{`Jurassic World Revealed`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.earplay.com/stories/jack-ryan/"
      }}>{`Jack Ryan`}</a>{`, but we were only able to release a few titles every year by keeping the technology to ourselves. By working with someone like Xandra or Capstone, we get to put out a ton more content and hopefully drive more excitement and interest.`}</p>
    <h2>{`How do you decide which stories are going to make engaging voice-driven interactive experiences?`}</h2>
    <p>{`When a story’s interactive, you’re a part of the story and no longer passive. You’re playing a role that has agency; your decisions affect the outcome and other characters. The choices you make can result in new endings or unique pathways.`}</p>
    <p>{`Here’s my playwright background kicking in. Oedipus Rex is one of the best put together stories, plot-wise. The thing is, it would be boring as an interactive experience because everything that happens is inevitable. If you can’t change the outcome or have an effect on the story, then you’re just idly moving along. It’s not as interesting.`}</p>
    <h2>{`How do you balance all the potential outcomes with creating a compelling story?`}</h2>
    <p>{`There’s a term from the gaming industry I use called possibility space. It’s the idea that when you’re moving down a particular branch, you’re still aware of how it could’ve gone in other ways. A facet of good design is providing that notion of a possibility space. You’re only going to be able to provide a finite number of choices, but you still want the possibilities to be endless.`}</p>
    <p>{`It’s not as fun when you offer options that are essentially the same, like “would you like to walk to work or drive to work?” I call that a non-choice, and there’s no possibility space there because you’re just moving along train tracks.`}</p>
    <h2>{`Any words of wisdom for anyone designing a voice experience for the first time?`}</h2>
    <p>{`The number one thing is don’t mess with the tech until you already know what you’re doing and you’ve already prototyped your design. The way I prototype for voice is talking to someone face to face. You want to test with the interface you’re designing for. Get your ideas down on notecards and roleplay them with someone. Their reactions will be your best resource.`}</p>
    <p>{`The other thing is the whole “less is more” adage. Simplicity matters. As you get into designing a voice user interface and the UX , it’s going to get very complicated quickly. I like to think about the two or three things that a person wants to do in the story in that moment and how I can articulate their choices to them. It’s easier to have a back and forth relationship with the participant than it is to try to think of the million possible things that someone might be able to say.`}</p>
    <p><em parentName="p">{`Jonathon spoke on `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/2019/04/26/superbot-2019-future-of-voice/"
        }}>{`“The Future of Voice”`}</a>{` panel at our annual SuperBot conference. Watch his panel `}<a parentName="em" {...{
          "href": "https://youtu.be/w32LFR_3P3Q"
        }}>{`here`}</a>{`.`}</em></p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+jon+myers"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      